<template>
  <layout-view
      navTitle="Отчеты"
      :navLoading="reportsState.isLoading"
  >
    <template v-slot:nav>
      <v-list dense>
        <v-list-item-group mandatory v-model="scriptIndex">
          <v-list-item v-for="(v, i) in scripts" :key="i">
            <v-list-item-content>
              <v-list-item-title>{{v.name}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
    <template v-slot:content>
      <div v-if="reportsState.isSuccess || scripts.length>0">
        <toolbar-view :title="scripts[scriptIndex].name"></toolbar-view>
        <v-form ref="form" class="px-4">
          <v-row class="rowSmall">
            <v-col v-for="(v, i) in scripts[scriptIndex].params" :key="i" cols="auto">
              <v-sheet width="200">
                <date v-if="v.type==='date'" :value.sync="v.value" />
              </v-sheet>
            </v-col>
            <v-col cols="auto">
              <v-btn depressed color="primary" @click="reportAction()" :loading="actionState.isLoading">Сформировать</v-btn>
            </v-col>
            <v-col cols="12">
              <v-sheet outlined rounded class="pa-3 d-inline-block">
                <v-row class="rowSmall">
                  <v-col cols="auto" class="align-self-center">
                    <v-icon size="63">mdi-file-document-outline</v-icon>
                  </v-col>
                  <v-col v-if="scripts[scriptIndex].result===null">
                    <div class="mb-2 body-2">Отчет еще не был сформирован</div>
                    <v-btn small depressed color="primary" disabled>Скачать</v-btn>
                  </v-col>
                  <v-col v-else>
                    <div class="mb-2 body-2">Последние данные: {{$tools.dateTimeFormat(scripts[scriptIndex].result.date, 'DD.MM.YYYY HH:mm:ss', 'YYYY-MM-DD HH:mm:ss')}}</div>
                    <v-btn small depressed color="primary" @click="$tools.openUrl(scripts[scriptIndex].result.url)">Скачать</v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </v-form>
        <div v-if="!$tools.isEmpty(scripts[scriptIndex].faq)">
          <toolbar-view title="FAQ"></toolbar-view>
          <div class="full-width px-4 mb-4" v-html="$tools.nl2br(scripts[scriptIndex].faq)"></div>
        </div>
      </div>
    </template>
  </layout-view>
</template>

<script>
import LayoutView from "@/components/LayoutView.vue";
import State from "@/plugins/state";
import axios from "axios";
import Api from "@/Api";
import ToolbarView from "@/components/ToolbarView.vue";
import {FormFieldRules} from "@/plugins/formFieldRules";
import Date from "@/componentsV2/base/Date.vue";

export default {
  components: {Date, ToolbarView, LayoutView},
  data: () => ({
    reportsState: new State(),
    actionState: new State(),
    scripts: [],
    scriptIndex: 0,
    formFieldRules: FormFieldRules,
    timer:null
  }),
  mounted(){
    this.load();
    this.timer = setInterval(()=>{
      this.load();
    },15*1000)
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    load(){
      this.reportsState.stateLoading();
      axios.get(Api.host + '/analytic/report/')
          .then((r) => {
            let arr = [];
            if(this.scripts.length>0){
              this.scripts[this.scriptIndex].params.forEach(v=>{
                arr.push(this.$tools.isEmpty(v.value) ? null : v.value);
              });
            }
            r.data.forEach(v=>{
              v.params.forEach(v1=>{
                v1.value = null;
              })
            })
            this.scripts = r.data;
            arr.forEach((v,k)=>{
              this.scripts[this.scriptIndex].params[k].value = v;
            });
            this.reportsState.stateSuccess();
          })
          .catch(e => {
            Api.responseError(e)
            this.reportsState.stateError();
          });
    },
    reportAction(){
      if (this.$refs.form.validate()){
        this.actionState.stateLoading();
        var data = [];
        this.scripts[this.scriptIndex].params.forEach(v=>{
          data.push({
            id:v.id,
            value:v.value,
          })
        });
        axios.post(Api.host + '/analytic/report/'+this.scripts[this.scriptIndex].id+"/", data)
        .then(() =>{
          this.actionState.stateSuccess();
          this.load();
          this.$store.commit('alertSuccess', 'Формирование отчета запущено');
        })
        .catch(e =>{
          this.actionState.stateError(e);
        });
      }
    }
  }
}
</script>